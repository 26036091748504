export const CONTRACT = 1;//with Ai feature  64, 65, 66, 67
export const CONTRACT_WORK = 48;//with Ai feature
export const CONTRACT_TAX = 43;
export const NEW_TYPE_CONTRACT = 163;
export const CONTRACT_TAX_1 = 35;//with Ai feature 
export const CONTRACT_TAX_2 = 36;//with Ai feature 
export const CONTRACT_TA_3 = 41;//with Ai feature
export const CONTRACT_FROM_OTHER_OPERATORS = 48;//with Ai feature
export const THREE_SIDE_CONTRACT = 33;

export const UNIVERSAL_ZAYAVKA = 153; // Универсальная заявка
// 154; == Закупка тавар
// 155; == Закупка оплатаа
// 156; == Заказы клиента
// 157; == Закупка УС
export const HILTON_JOB_REQ_FORM = 158;
export const HILTON_SALARY_CHANGE_FORM = 159;
export const HILTON_PURCHASE_ORDER_FORM = 160;
export const HILTON_VOCATION_FORM = 161;

export const INVOICE_STANDART_TAX = 2;
export const ACT_COMPLETED = 3;
export const ACT_ACCEPTANCE = 4;
export const ACT_RECONCILIATION = 5;
export const EMPOWERMENT = 6;
export const AGENT_REPORT = 7;
export const LETTER = 8;
export const OTHER = 9;
export const AGREEMENT = 11;
export const INVOICE_EXCISE_TAX = 13;
export const INVOICE_EXCISE_SERVICE = 49;
export const INVOICE_EXCISE_CONSTRUCTION_SERVICE = 50;
export const GSM_TAX = 14;
export const INVOICE_THREE_SIDE_STANDART_TAX = 18;
export const INVOICE_PHARM1_TAX = 19;
export const INVOICE_COMMITENT_TAX = 21;
export const INVOICE_COMMISIONER_TAX = 22;
export const INVOICE_PHARM2_TAX = 24;
export const EMPOWERMENT_TAX = 27;
export const RENT_CONTRACT = 28;
export const ACT_TAX = 29;
export const THREE_SIDE_ACT_COMPLETED = 31;
export const INVENTORY_ITEMS = 37;
export const COMPETITORSLIST = 38;
export const ACT_VERIFICATION = 42;
export const INVOICE_SERVICESE = 44;
export const INVOICE_CONSTRUCTION_SERVICESE = 45;
export const INVOICE_TRANSPORTION = 47;
export const APPLICATION = 90;
export const ADDITIONAL_AGREEMENT = 93;
export const SCHEDULE_TABLE = 94;
export const INVOICE_SUPPLIES = 103;
export const INVOICE_TRANSPORT = 96;
export const INVOICE_CMR = 97;
export const INVOICE_GSM = 170;
export const INVOICE_VED = 98;
export const INVOICE_BUFFER_DEPARTMENT = 99;
export const INVOICE_IT_DEPARTMENT = 100;
export const INVOICE_PTO = 101;
export const INVOICE_ADVERTISEMENT = 102;
export const INVOICE_FINANCIAL = 105;
export const INVOICE_SUPPLIES_SERVICE = 106;
export const INVOICE_MATERIALS = 107;
export const INVOICE_PROJECT_MANAGER = 108;
export const INVOICE_SMALL_MECHANIZATION = 109;
export const WAYBILL_TAX = 104;
export const CONSTRUCTION_INVOICE = 171;

export const INVOICE_LABORATORY = 136; // SMALL MECHANIZATION
export const INVOICE_ACCOUNTING = 139; // ACCOUNTING
export const INVOICE_SALES_DEPARTMENT = 140; // SALES_DEPARTMENT
export const INVOICE_MANAGEMENT = 141; // MANAGEMENT
export const INVOICE_AXO = 142; // АХО
export const INVOICE_LEGAL_DEPARTMENT = 143; // LEGAL_DEPARTMENT
export const INVOICE_ENERGY = 144; // ENERGY
export const INVOICE_IMPORT = 152; // IMPORT
export const NEW_TYPE_INVOICE = 92;

//    CONTRACT
export const CONTRACT_TRANSPORT = 110; // TRANSPORT
export const CONTRACT_GSM = 111; // GSM
export const CONTRACT_VED = 112; // VED
export const CONTRACT_BUFFER_DEPARTMENT = 113; // BUFFER_DEPARTMENT
export const CONTRACT_IT_DEPARTMENT = 114; // IT_DEPARTMENT
export const CONTRACT_PTO = 115; // PTO
export const CONTRACT_ADVERTISEMENT = 116; // ADVERTISEMENT
export const CONTRACT_SUPPLIES = 117; // SUPPLIES
export const CONTRACT_FINANCIAL = 118; // Financial
export const CONTRACT_SUPPLIES_SERVICE = 119; // SUPPLIES SERVICE
export const CONTRACT_MATERIALS = 120; // materials
export const CONTRACT_PROJECT_MANAGER = 121; // PROJECT MANAGER
export const CONTRACT_SMALL_MECHANIZATION = 122; // SMALL MECHANIZATION
export const CONTRACT_LABORATORY = 137; // _LABORATORY
export const CONTRACT_ACCOUNTING = 145; // ACCOUNTING
export const CONTRACT_SALES_DEPARTMENT = 146; // SALES_DEPARTMENT
export const CONTRACT_MANAGEMENT = 147; // MANAGEMENT
export const CONTRACT_AXO = 148; // АХО
export const CONTRACT_LEGAL_DEPARTMENT = 149; // LEGAL_DEPARTMENT
//    ACT
export const ACT_TRANSPORT = 123; // TRANSPORT
export const ACT_GSM = 124; // GSM
export const ACT_VED = 125; // VED
export const ACT_BUFFER_DEPARTMENT = 126; // BUFFER_DEPARTMENT
export const ACT_IT_DEPARTMENT = 127; // IT_DEPARTMENT
export const ACT_PTO = 128; // PTO
export const ACT_ADVERTISEMENT = 129; // ADVERTISEMENT
export const ACT_SUPPLIES = 130; // SUPPLIES
export const ACT_FINANCIAL = 131; // Financial
export const ACT_SUPPLIES_SERVICE = 132; // SUPPLIES SERVICE
export const ACT_MATERIALS = 133; // materials
export const ACT_PROJECT_MANAGER = 134; // PROJECT MANAGER
export const ACT_SMALL_MECHANIZATION = 135; // SMALL MECHANIZATION
export const ACT_LABORATORY = 138; // _LABORATORY
export const ACT_ACCOUNTING = 151;
export const NEW_TYPE_ACT = 162;
export const INCOMING_CASH_ORDER = 168;//
export const OUTGOING_CASH_ORDER = 169;

export const OTHER_FROM_OTHER_OPERATORS = 10,
  SPECIFICATION_FROM_OTHER_OPERATORS = 25,
  LETTER_FROM_OTHER_OPERATORS = 150;
